import { FormControl, Icon, IconButton, TextField, } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { isNumeric } from "helpers";
/* eslint react/prop-types:0 */
export default function MultiFilterInput({ filterList, onChange, index, column, filterData, type }) {
    const [value, setValue] = useState("")
    const [error, setError] = useState("")
    return (<FormControl>
        <TextField
            aria-describedby="component-error-text"
            margin="none"
            error={error}
            helperText={error}
            variant="standard"
            label={column.label}
            InputProps={{
                endAdornment: (<IconButton
                    onClick={() => {
                        setError("")
                        if (filterList[index].includes(value)) {
                            setError(`Already filtering by ${value}`)
                            return
                        }
                        if (type === "number" && !isNumeric(value)) {
                            setError(`${value} is not a number!`)
                            return
                        }
                        filterList[index].push(value)
                        setValue("")
                        onChange(filterList[index], index, column)
                    }}>
                    <Icon>
                        add
                    </Icon>
                </IconButton>)
            }}
            value={value}
            onChange={(e) => { setValue(e.target.value) }} />
    </FormControl>)
}
/*
MultiFilterInput.propTypes = {
    filterList: PropTypes.array,
    onChange: PropTypes.func,
    index: PropTypes.int,
} */
