
/**
 * Check if string is a number
 * @param {*} str 
 * @returns 
 */
function isNumeric(str) {
    if (typeof str !== "string") return false // we only process strings!  
    return !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
/**
   * Converts risk to a integer number.
   * Used for sorting.
   * @param {String} risk 
   * @returns {Number}
   */
const riskToInt = (risk) => {
    switch (risk) {
        case "Critical":
            return 4
        case "High":
            return 3
        case "Medium":
            return 2
        case "Low":
            return 1
        case "None":
            return 0
        default:
            return 5
    }
}

const returnNAIfNull = value => value || "-";

/* eslint prefer-template:0 */
/**
 * Truncates a string with elipses
 * @param {*} str String to truncate
 * @param {*} n Amount of characters to allow
 * @returns 
 */
 function truncate(str, n) {
    if (typeof str !== "string") return returnNAIfNull(str) // we only process strings!  
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
  };


  /**
   * Adds commas to number
   * @param {*} x 
   * @returns 
   */
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export {
    isNumeric,
    riskToInt,
    returnNAIfNull,
    truncate,
    numberWithCommas
}