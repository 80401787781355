import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Button, Alert, AlertTitle } from "@mui/material";
import { useMaterialUIController, setToast } from "context";
import MDSnackbar from './MDSnackbar';
import create from 'zustand/vanilla'
import reactCreate from 'zustand'

function getDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();
    let hour = now.getHours();
    let minute = now.getMinutes();
    let second = now.getSeconds();

    if (month.toString().length === 1) {
        month = `0${month}`;
    }
    if (day.toString().length === 1) {
        day = `0${day}`;
    }
    if (hour.toString().length === 1) {
        hour = `0${hour}`;
    }
    if (minute.toString().length === 1) {
        minute = `0${minute}`;
    }
    if (second.toString().length === 1) {
        second = `0${second}`;
    }
    const dateTime = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    return dateTime;
}

const errorSnackBarStore = create((set) => ({ 
    show: false,
    title: "",
    msg: "",
    color: "success",
    close: () => set({ show: false })
 }))

 const { getState, setState} = errorSnackBarStore

 const useErrorSnackBarStore = reactCreate(errorSnackBarStore)

// Helper function to show snackbar
 const showErrorSnackBar = (title, msg, color = "success") => {
    setState({
        show: true,
        title,
        msg,
        color
    })
 }

export {
    showErrorSnackBar,
    errorSnackBarStore
}

export default function ErrorSnackBar() {
    const { title, msg, show, close, color } = useErrorSnackBarStore();


    const handleClose = (event, reason) => {
        if (color !== "success" && reason === "clickaway") {
            return
        }

        close()
    }

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <MDSnackbar
            open={show}
            color={color}
            title={title}
            close={handleClose}
            content={msg}
            icon="check"
            dateTime=""
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
    )
}