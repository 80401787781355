import colors from "../../assets/theme-dark/base/colors"
import borders from "../../assets/theme-dark/base/borders"
import { createTheme } from "@mui/material";

const getDarkTheme = () => createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.background.card,
                    borderRadius: borders.borderRadius.xl,
                    borderColor: borders.borderColor,
                    color: `${colors.text.main}`
                }
            }
        },
        // MUIDataTableHeadCell-toolButton
        MUIDataTableToolbar: {
            styleOverrides: {
                icon: {
                    color: colors.secondary.focus,
                    "&:hover": {
                        color: colors.secondary.main,
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: colors.text.main
                }
            }
        },
        MUIDataTableToolbarSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.info.main
                }
            }
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: "center"
                },
                toolButton: {
                    margin: "0px 16px 0px 16px !important"
                },
                sortActive: {
                    color: colors.info.focus,
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    color: `${colors.info.focus} !important`,
                }
            }
        },
        MUIDataTableSelectCell: {
            styleOverrides: {
                icon: {
                    color: "white"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    color: "white"
                },
                deleteIcon: {
                    color: colors.secondary.focus,
                    "&:hover": {
                        color: colors.secondary.main,
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: colors.text.main,
                    borderBottom: `1px solid #34476761`,
                    padding: 2,
                    textAlign: "center"
                },
                head: {
                    backgroundColor: `${colors.background.card} !important`,
                    justifyContent: "center"
                },
                footer: {
                    border: "none",
                }
            }
        }
    }
})

const getLightTheme = () => createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: borders.borderRadius.xl,
                }
            }
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: "center"
                },
                toolButton: {
                    margin: "0px 16px 0px 16px !important"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 2,
                    textAlign: "center"
                },
                head: {
                    justifyContent: "center"
                },
                footer: {
                    border: "none",
                }
            }
        }
    }
  })

export {
    getDarkTheme,
    getLightTheme
}