import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

 function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        // TODO: Invalidate token with post request

        // Remove the token from localstorage
        localStorage.clear()

        navigate("/authentication/sign-in")
    }, [])

    return <></>

}

export default Logout