// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import { useEffect, useState } from "react";
import { getScans } from "api";

// Dashboard components
import ScanTable from "./components/ScanTable";
import { CircularProgress, Fade } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function Scans() {
  const [loading, setLoading] = useState(false)
  const [numberScans, setNumberScans] = useState(0)
  const [numberComputers, setNumberComputers] = useState(0)
  const [numberVulns, setNumberVulns] = useState(0)

  const [scans, setScans] = useState([])


  const updateScans = () => {
    setLoading(true)
    getScans()
      .then((data) => {
        setNumberScans(data.total_scans)
        setNumberComputers(data.total_computers)
        setScans(data.scans)
        setNumberVulns(data.total_vulns)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    updateScans()
  }, [])

  const topStats = (<Grid container spacing={3}>
    <Grid item xs={12} md={12} lg={4}>
      <MDBox mb={1.5}>
        <ComplexStatisticsCard
          color="info"
          icon="sensors"
          title="Number of Scans"
          count={numberScans}
        />
      </MDBox>
    </Grid>
    <Grid item xs={12} md={12} lg={4}>
      <MDBox mb={1.5}>
        <ComplexStatisticsCard
          color="secondary"
          icon="computer"
          title="Total Computers Scanned"
          count={numberComputers}
        />
      </MDBox>
    </Grid>
    <Grid item xs={12} md={12} lg={4}>
      <MDBox mb={1.5}>
        <ComplexStatisticsCard
          color="error"
          icon="priority_high"
          title="Total Vulnerabilities"
          count={numberVulns}
        />
      </MDBox>
    </Grid>
    
  </Grid>)

  return (

    <DashboardLayout>
      <DashboardNavbar />
      {loading &&
        <MDBox py={3} sx={{ margin: "auto", textAlign: "center" }}>
          <CircularProgress color="inherit" />
          <MDTypography fontWeight="light" variant="h6">
            Loading...
          </MDTypography>
        </MDBox>
      }

      <Fade in={!loading} timeout={800}>
        {numberScans === 0 ?
          <MDTypography
            color="secondary"
            textAlign="center">
            No scans to show!
          </MDTypography> :
          <MDBox py={3}>
            {topStats}
            <MDBox>
              <ScanTable scans={scans} update={updateScans} />
            </MDBox>
          </MDBox>
        }
      </Fade>
      <Footer />
    </DashboardLayout>
  );
}

export default Scans;
