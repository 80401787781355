import { showErrorSnackBar } from 'components/errorsnackbar';

import axios, { AxiosError } from 'axios';
import { API_URL } from 'CONSTS';

/**
 * @param {AxiosError} e Error returned from axios
 * @param {String} title Title for error if response returned, else wise default title will be Error
 * @param {String} msg If no response message, this message will be used instead
 */
const handleError = (e, title, msg = "Unknown Error") => {
    if (e.response) {
        if (e.response.data.detail) {
            if (!Array.isArray(e.response.data.detail)) {
                // eslint-disable-next-line no-param-reassign
                msg = e.response.data.detail;
            }
        }
        showErrorSnackBar(title, msg, "error")
    } else {
        showErrorSnackBar("Error!", String(e), "error")
    }
}

/**
 * Get a token back from the api with the given username and password
 * @param {*} username Username of user
 * @param {*} password Password of user
 * @returns A JWT token
 */
const onAuthenticate = (username, password) => {
    return axios(`${API_URL}/login?username=${username}&password=${password}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Sign-In Error!")
            throw e;
        });
};

/**
 * Gets scans that belong to the authorized user, all if admin.
 * @returns Promise, A list of scans
 */
const getScans = () => {
    return axios(`${API_URL}/scans`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error Getting Scans!")
            throw e;
        });
};
/**
 * Call this method to delete a scan
 * @param {String} scanID ScanID to delete
 * @returns 
 */
const deleteScan = (scanID) => {
    return axios(`${API_URL}/scans/${scanID}`, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => {
            showErrorSnackBar("Success!", "Successfully deleted that scan!")
            return response.data
        })
        .catch(e => {
            handleError(e, "Error While Deleting!")
            throw e;
        });
};

/**
 * Returns the overview of a scan with the given id. Used for statistics
 * @param {String} scanID Scan id to get overview for
 */
const getScanOverview = (scanID) => {
    if (!scanID) throw new Error("scanID not passed!")
    return axios(`${API_URL}/scanoverview/${scanID}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error getting scan overview!")
            throw e;
        });
}

/**
 * Returns results for the computers scan data table
 * @param {Number} scanID 
 * @param {Number} page 
 * @param {Number} limit 
 * @param {*} sort 
 * @param {*} filter 
 * @returns 
 */
const getScanTable = (scanID, page, limit, sort, filter, controller) => {
    return axios(`${API_URL}/scantable/${scanID}`, {
        method: 'GET',
        params: {
            page,
            limit,
            filter,
            sort
        },
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        signal: controller.signal
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error loading scan table!")
            throw e;
        });
}

/**
 * Gets all vulnerabilities associated with a certain computer ID
 * @param {String | Number} computerID Computer ID you want info about
 * @param {Object} filter The filter from datatable
 * @returns 
 */
const getComputerInfo = (computerID, filter) => {
    return axios(`${API_URL}/computer/${computerID}`, {
        method: 'GET',
        params: {
            filter
        },
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error loading scan table!")
            throw e;
        });
}

/**
 * Returns number of hosts with a certain vuln
 * @param {*} vulnName 
 * @param {*} scanID 
 * @returns 
 */
const getHostsWithVuln = (vulnHash, scanID) => {
    return axios(`${API_URL}/computers/vulns/`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        params: {
            vuln_hash: vulnHash,
            scan_id: scanID
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error loading hosts with that vulnerability.")
            throw e;
        });
}

export {
    getScans,
    onAuthenticate,
    deleteScan,
    getScanOverview,
    getScanTable,
    getComputerInfo,
    getHostsWithVuln
}