/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getScanOverview } from "api";
import { Fade, Tooltip } from "@mui/material";
import ScanDataTable from "components/ScanDataTable";


const FADE_TIME = 1000


const Statistics = ({ scanID }) => {

  const [loading, setLoading] = useState(true)
  const [computerCount, setComputerCount] = useState(0)
  const [vulnCount, setVulnCount] = useState(0)
  const [criticalCount, setCriticalCount] = useState(0)
  const [vulnTotalChart, setVulnTotalChart] = useState({ datasets: [], labels: [] })
  const [weightedLineGraph, setWeightedLineGraph] = useState({ datasets: [], labels: [] })
  const [riskChart, setRiskChart] = useState({ datasets: [], labels: [] })

  const [atRiskCount, setAtRiskCount] = useState(0)

 

  const updateScanOverview = () => {
    setLoading(true)
    getScanOverview(scanID)
      .then((data) => {
        setComputerCount(data.computer_count)
        setVulnCount(data.vuln_count)
        setCriticalCount(data.critical_count)
        setAtRiskCount(data.at_risk_count)

        // Make sure data exists and is an array
        if(data.vuln_total_chart && data.vuln_total_chart.datasets && data.vuln_total_chart.datasets.length > 0){
          setVulnTotalChart(data.vuln_total_chart)
        }
        // Make sure data exists and is an array
        if(data.weighted_line_graph && data.weighted_line_graph.datasets && data.weighted_line_graph.datasets.length > 0){
          setWeightedLineGraph(data.weighted_line_graph)
        }
         // Make sure data exists and is an array
         if(data.risk_chart && data.risk_chart.datasets && data.risk_chart.datasets.length > 0){
          setRiskChart(data.risk_chart)
        }
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    updateScanOverview()
  }, [scanID])

  return (
    <MDBox>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="computer"
                title="Computers"
                count={computerCount}
                loading={loading}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="priority_high"
                title="Vulnerabilities"
                count={vulnCount}
                loading={loading}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <MDBox mb={1.5}>
              <Tooltip title="CVSS of 10" placement="top">
                <div>
                  <ComplexStatisticsCard
                    color="error"
                    icon="error"
                    title="Critical Vulnerabilities"
                    count={criticalCount}
                    loading={loading}
                  />
                </div>
              </Tooltip>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <MDBox mb={1.5}>
              <Tooltip title="Count of Medium, High, and Critical" placement="top">
                <div>
                  <ComplexStatisticsCard
                    color="error"
                    icon="error"
                    title="At Risk"
                    count={atRiskCount}
                    loading={loading}
                  />
                </div>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {/* Charts */}
      <MDBox mt={4.5}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={3}>
              <ReportsBarChart
                color="error"
                title="Risk Values"
                description="A count of risks between None/Low/Medium/High"
                chart={riskChart}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={3}>
              <ReportsBarChart
                color="info"
                title="Vulnerability Totals"
                description="Sum of CVSS values between 0-10"
                chart={vulnTotalChart}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="primary"
                title="Scores"
                description="Scores aggregated and their values"
                chart={weightedLineGraph}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
};

Statistics.propTypes = {
  scanID: PropTypes.string.isRequired
}


function Dashboard() {
  const { scanID } = useParams();

  useEffect(() => {
  }, [scanID])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Fade in timeout={FADE_TIME}>
        <MDBox>
          <Statistics scanID={scanID} />

          <MDBox mt={4.5} mb={4.5}>
            <ScanDataTable />
          </MDBox>
        </MDBox>
      </Fade>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
