import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URL,BASENAME } from 'CONSTS';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function AuthenticatedView({ children }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)


    /**
     * Function to validate whether a token is valid or not.
     */
    const validateToken = (source) => {
        // TODO: store this in a store and dont fetch it if its been X amount of minutes.
        const token = localStorage.getItem("token")

        if (token) {
            const config = {
                headers: { Authorization: `Bearer ${token}` },
                cancelToken: source.token
            };
            axios
                .get(`${API_URL}/validateToken`, config)
                .then(() => setLoading(false))
                .catch((e) => {
                    navigate(`${BASENAME}/authentication/sign-in`)
                })
        } else {
            navigate(`${BASENAME}/authentication/sign-in`)
        }
    }


    useEffect(() => {
        const source = axios.CancelToken.source();

        validateToken(source)

        return () => {
            // Cancel axios request if unmounted
            source.cancel("Cancelling in cleanup");
        };
    }, []);

    if (loading) {
        return <></>
    }

    return children
}

AuthenticatedView.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AuthenticatedView;