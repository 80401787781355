// ConfirmDialog.jsx
// material ui
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    Typography,
    Divider,
} from '@mui/material';
import create from 'zustand';
import CloseIcon from '@mui/icons-material/Close';
import { useMaterialUIController } from 'context';
import { useEffect, useState } from 'react';
import MDButton from 'components/MDButton';

const useConfirmDialogStore = create((set) => ({
    title: '',
    message: '',
    onSubmit: undefined,
    close: () => set({ onSubmit: undefined }),
}));

const confirmDialog = (title, message, onSubmit) => {
    useConfirmDialogStore.setState({
        title,
        message,
        onSubmit,
    });
};

const ConfirmDialog = () => {
    // destructure the store data and functions
    const { title, message, onSubmit, close } = useConfirmDialogStore();

    return (
        // if the onSubmit is undefined the dialog will be closed. 
        // close() function sets the onSubmit to undefined, 
        // so it will close the dialog, if we pass it to the onClose attribute.
        <Dialog
            open={Boolean(onSubmit)}
            onClose={close}
            maxWidth="sm"
            fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={close}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Typography >{message}</Typography>
            </DialogContent>
            <DialogActions>
                <MDButton color="secondary" variant="contained" onClick={close}>
                    Cancel
                </MDButton>
                <MDButton
                    color="error"
                    variant="contained"
                    onClick={() => {
                        if (onSubmit) {
                            onSubmit();
                        }
                        close();
                    }}
                >
                    Confirm
                </MDButton>
            </DialogActions>
        </Dialog>
    )
};




export {
    confirmDialog,
    useConfirmDialogStore,
    ConfirmDialog
}

export default ConfirmDialog;