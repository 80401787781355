import { CircularProgress, IconButton, Table, TableCell, TableHead, TableRow, TableBody, ThemeProvider, Modal, Typography, Tooltip, Grid, Box, Button, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";

import { useEffect, useState } from "react";
import { getScanTable, getComputerInfo, getHostsWithVuln } from "api";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { getDarkTheme, getLightTheme } from "./tableTheme";
import themeDark from "assets/theme-dark";
import { useMaterialUIController } from "context";
import theme from "assets/theme";
import CheckIcon from '@mui/icons-material/Check';
import MDButton from "components/MDButton";
import { isNumeric, riskToInt, returnNAIfNull } from "helpers";
import MultiFilterInput from "components/MUIDataTableExtensions/MultiFilterInput";
import create from 'zustand'
import axios from "axios";

export const useFilterStore = create(set => ({
  filter: {},
  setFilter: (filter) => set(state => ({ filter })),
}))


/* eslint react/prop-types: 0 no-param-reassign: 0 */

const VulnModal = ({ selectedRow, setSelectedRow }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    boxShadow: 24,
    p: 4,
    backgroundColor: "background.card",
    color: "text.main",
    maxHeight: "99%",
    overflowY: "auto",
    borderRadius: "10px"
  };

  const cellStyle = {
    padding: 0,
    color: "text.main"
  }


  const { scanID } = useParams()


  // eslint-disable-next-line
  const { vuln } = selectedRow ? selectedRow : { vuln: null }
  const [affectedHostsCount, setAffectedHostsCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const { setFilter } = useFilterStore()
  useEffect(() => {

    if (vuln != null) {
      // Load hosts with same vulnerability
      getHostsWithVuln(vuln.hash, scanID).then((c) => {
        setAffectedHostsCount(c)
      }).finally(() => {
        setLoading(false)
      })
    }

  }, [selectedRow])
  return <ThemeProvider theme={darkMode ? themeDark : theme}>
    <Modal
      open={!!selectedRow}
      onClose={() => { setSelectedRow(null);}}
    >
      <MDBox sx={modalStyle} >
        {!!selectedRow &&
          <>
            <Typography variant="h4">
              {riskToInt(vuln.risk) > 0 ? `(${vuln.risk})` : ""} {vuln.host} <br /> {vuln.name}
            </Typography>
            <Typography variant="body" >
              {vuln.description}
            </Typography>
            <Typography variant="h5" mt={3}>
              Solution:
            </Typography>
            <Typography variant="body" >
              {vuln.solution}
            </Typography>
            <Typography variant="h5" mt={3}>
              Affected Ports:
            </Typography>
            <Table sx={{ maxHeight: "20px", overflowY: "auto" }}>
              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell sx={{ color: "text.main" }}>Port Number</TableCell>
                  <TableCell sx={{ color: "text.main" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRow.ports.map((port, i) =>
                  <TableRow key={`${port}${vuln.host}${vuln.id}${i}`}>
                    <TableCell sx={cellStyle}>
                      {port}
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      <Tooltip title="Mark as resolved?">
                        <IconButton color="success" onClick={() => {
                          // TODO: Implment resolving
                          // alert("Not implemented!");
                        }}>
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={12} md={6}>
                {loading ? <CircularProgress /> :
                  <>
                    <Typography variant="h5" mt={3}>
                      {affectedHostsCount} Affected Hosts.
                    </Typography>
                  </>
                }
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }} mt="20px">
                  <MDButton onClick={() => { setFilter({ "vuln_name": [vuln.name] }) }}>Filter table by this Vulnerability</MDButton>
                </Box>
              </Grid>
            </Grid>
          </>
        }
      </MDBox>
    </Modal>
  </ThemeProvider>
}

function ExpandedRow({ hostID, colSpan }) {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)

  const filter = useFilterStore(state => state.filter)

  const columns = [
    {
      name: "vuln.name",
      label: "Name",
    },
    {
      name: "vuln.risk",
      label: "Risk",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = riskToInt(obj1.data)
            const val2 = riskToInt(obj2.data)
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      }
    },
    {
      name: "vuln.cve_name",
      label: "CVE",
      options: {
        filter: true,
        sort: true,
        filterType: "textField"
      }
    },
    {
      name: "vuln.synopsis",
      label: "Synopsis",
    },
    {
      name: "vuln.cvss",
      label: "CVSS",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => returnNAIfNull(rows[dataIndex].vuln.cvss),
      }
    },
    {
      name: "vuln.protocols",
      label: "Protocols",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const { protocols } = rows[dataIndex]
          return protocols.join(", ")
        },
      }
    },
    {
      name: "vuln.ports",
      label: "Affected Ports",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const { ports } = rows[dataIndex]
          return ports.join(", ")
        },
      }
    },
    /* {
      name: "solution",
      label: "Solution",
    },
    {
      name: "see_also",
      label: "See Also",
    }, */
  ];

  const [selectedRow, setSelectedRow] = useState(null)

  useEffect(() => {
    getComputerInfo(hostID, filter).then((data) => {
      setRows(data.computer.merged_vulnerabilities)
    }).finally(() => {
      setLoading(false)
    })
  }, [hostID])



  return (
    <TableRow >
      <TableCell colSpan={colSpan}>
        <VulnModal selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
        {loading ? <CircularProgress /> :
          <MUIDataTable
            columns={columns}
            data={rows}
            options={{
              selectableRowsHideCheckboxes: true,
              download: false,
              search: false,
              print: false,
              filter: false,
              viewColumns: false,
              selectableRowsOnClick: true,
              selectableRowsHeader: false,
              enableNestedDataAccess: ".",
              rowsPerPage: 20,
              rowsPerPageOptions: [10, 20, 30, 50],
              onRowSelectionChange: (e) => { setSelectedRow(rows[e[0].dataIndex]) },
              selectableRows: "single",
              selectToolbarPlacement: "none",
              sortOrder: { name: "vuln.risk", direction: "desc" },
              // tableBodyHeight: "280px",
            }}
          />}
      </TableCell>
    </TableRow>
  );
}

ExpandedRow.propTypes = {
  hostID: PropTypes.number.isRequired,
  colSpan: PropTypes.number.isRequired
}

export default function ScanDataTable() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState(15)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState({})
  const { scanID } = useParams()

  const filter = useFilterStore(state => state.filter)
  const setFilter = useFilterStore(state => state.setFilter)

  let abortController = new AbortController();

  useEffect(() => {
    // Cancel old requests
    abortController.abort()
    // Create new cancel controller
    abortController = new AbortController();

    setLoading(true)
    getScanTable(scanID, page, limit, sort, filter, abortController)
      .then((e) => {
        setData(e.computers)
        setCount(e.count)
      }).finally(() => {
        setLoading(false)
      })
  }, [page, limit, sort, filter])

  const handleFilter = (tableState) => {
    const combined = {}
    // Combine columns and filters
    tableState.columns.forEach((column, i) => {
      combined[column.name] = tableState.filterList[i]
    })

    setFilter(combined)
  }

  const options = {
    search: false,
    serverSide: true,
    count,
    page,
    customSearchRender: debounceSearchRender(5000),
    expandableRows: true,
    expandableRowsOnClick: true,
    expandableRowsHeader: false,
    rowsPerPage: limit,
    rowsPerPageOptions: [10, 15, 20, 30, 50, 100],
    selectableRowsHideCheckboxes: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return <ExpandedRow hostID={data[rowMeta.rowIndex].id} colSpan={colSpan} />
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page)
          break;
        case 'sort':
          setSort(tableState.sortOrder)
          break;
        case 'changeRowsPerPage':
          setLimit(tableState.rowsPerPage)
          break;
        case 'search':
          break;
        case 'resetFilters':
        case 'filterChange':
          handleFilter(tableState)
          break;
        case 'propsUpdate':
          break;
        case 'onSearchOpen':
          break;
        default:
        // eslint-disable-next-line
        // console.log('action not handled.', action, tableState);
      }
    }
  };


  const columns = [
    {
      name: "vuln_name",
      label: "Vuln Name",
      options: {
        filter: true,
        filterType: "textField",
        display: "excluded",
        filterList: filter.vuln_name
      }
    },
    {
      name: "port_name",
      label: "Port",
      options: {
        filter: true,
        filterType: "custom",
        display: "excluded",
        filterOptions: {
          display: (filterList, onChange, index, column, filterData) => {
            return <MultiFilterInput filterList={filterList} onChange={onChange} index={index} column={column} filterData={filterData} type="number" />
          }
        }
      }
    },
    {
      name: "host",
      label: "Host",
      options: {
        filter: true,
        sort: true,
        filterType: "textField"
      }
    },
    {
      name: "at_risk_count",
      label: "At Risk",
      options: {
        filter: false,
        sort: true,
        filterType: "textField",
      }
    },
    {
      name: "vulnerability_count",
      label: "Total Vulnerabilities",
      options: {
        filter: false,
        sort: true,
        filterType: "textField",
      }
    },
    {
      name: "open_ports",
      label: "Open Ports",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "max_cvss",
      label: "Highest CVSS",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => returnNAIfNull(data[rowIndex].max_cvss),
        filterOptions: { names: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }
      }
    },
    {
      name: "cvss_score",
      label: "Score",
      options: {
        filter: false,
        sort: true,
        filterType: "textField",
      }
    },
  ];

  /**
   * Shows during any loading of the table.
   * @param {*} props 
   * @returns 
   */
  const loadingTableBody = (props) => {
    return <TableHead>
      <TableRow>
        <TableCell colSpan={props.columns.length}>
          <MDBox m={5} sx={{ textAlign: "center" }}>
            <CircularProgress title="Loading..." />
          </MDBox>
        </TableCell>
      </TableRow>
    </TableHead>
  }

  return (
    <ThemeProvider theme={darkMode ? getDarkTheme() : getLightTheme()}>
      <MUIDataTable
        title="Scan List"
        columns={columns}
        options={{
          ...options,
          customFilterDialogFooter: () => {
            return (
              <MDBox mt={2}>
                <FormGroup>
                  <FormControlLabel sx={{color: "black"}} control={<Checkbox defaultChecked disabled/>} label="Filter expanded rows too?" />
                </FormGroup>
              </MDBox>

            )
          }
        }}
        data={data}
        components={{
          TableBody: loading ? loadingTableBody : null
        }}
      />
    </ThemeProvider>
  )
}

