/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Soft UI Dashboard Materail-UI example components
import DataTable from "examples/Tables/DataTable";

import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard React components
import MDProgress from "components/MDProgress";
import { IconButton, Icon } from "@mui/material";
import GridViewIcon from '@mui/icons-material/GridView';
import { useNavigate } from "react-router-dom";
import { BASENAME } from "CONSTS";
import { deleteScan } from "api";
import { confirmDialog } from "components/ConfirmDialog";

function ScanTable({ scans, update }) {
  const defaultCols = [
    { Header: "Client Name", accessor: "client", align: "center" },
    { Header: "Date Scanned", accessor: "date_scanned", align: "left" },
    { Header: "Computers", accessor: "computer_count", align: "center" },
    { Header: "Vulnerabilities", accessor: "vuln_count", align: "center" },
    { Header: "Resolved", accessor: "resolved", align: "center" },
    { Header: "Actions", accessor: "actions", align: "center" }
  ]
  const [rows, setRows] = useState([])
  const navigate = useNavigate();
  const [cols, setCols] = useState(defaultCols)

  function row(scan) {
    return {
      date_scanned: <MDTypography variant="caption" color="text" fontWeight="medium">
        {scan.date}
      </MDTypography>,
      computer_count: (<MDTypography variant="caption" color="text" fontWeight="medium">
        {scan.computer_count}
      </MDTypography>),
      vuln_count: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {scan.total_vulns}
        </MDTypography>
      ),
      resolved: (
        <Tooltip title="Vulnerabilities Resolved: 0">
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={0} color="info" variant="gradient" label={false} />
          </MDBox>
        </Tooltip>
      ),
      actions: (
        <MDBox>
          <Tooltip title="View Scan">
            <IconButton color="secondary" onClick={() => {
              navigate(`${BASENAME}/scans/${scan.id}`)
            }}>
              <GridViewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete this scan?">
            <IconButton
              onClick={() => {
                confirmDialog(`Are you sure you want to delete this scan?`, `${scan.date}`, () => {
                  deleteScan(scan.id)
                    .then((e) => {
                      update()
                    })
                })
              }}
              color="error">
              <Icon>
                delete
              </Icon>
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
      client: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {scan.client.name}
        </MDTypography>)
    }
  }

  useEffect(() => {
    setRows(scans.map(scan =>  row(scan)))
  }, [scans])

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Scans
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns: cols, rows }}
          showTotalEntries={false}
          isSorted
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ScanTable.defaultProps = {
  scans: [],
  update: () => { }
};

// Typechecking props for the ComplexStatisticsCard
ScanTable.propTypes = {
  scans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    client_id: PropTypes.string,
    date: PropTypes.string,
    computer_count: PropTypes.number,
    total_vulns: PropTypes.number
  })),
  update: PropTypes.func
};

export default ScanTable;
